<template>
  <div class="grid items-center justify-start">
    <div class="group relative mx-2">
      <div
        :class="classStatus"
        class="animate-tilt absolute -inset-0.5 rounded-lg opacity-40 blur transition duration-1000"></div>
      <div class="relative flex h-full w-full items-center">
        <span
          :class="classStatus"
          class="relative inline-flex h-2.5 w-2.5 rounded-full"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  status: {
    type: String,
    default: 'CONNECTED',
  },
})

const classStatus = computed(() => {
  switch (props.status) {
    case 'CONNECTED':
      return 'bg-green-300'
    case 'DISCONNECTED':
      return 'bg-red-300'
    case 'CONNECTING':
      return 'bg-yellow-300'
    default:
      return 'bg-gray-300'
  }
})
</script>
